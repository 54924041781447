<form
  action=""
  class="grip w-full max-w-full pb-5"
  data-testid="custom-fields-form"
  [formGroup]="form"
  (submit)="submit()">
  <div class="flex flex-col" data-testid="custom-fields-form__container">
    <div class="flex flex-col gap-2 mb-4" data-testid="custom-fields-form__category">
      <label class="font-medium">{{ 'Category' | translate }}</label>

      <offconon-category-path-dropdown-search-common
        data-testid="custom-fields-form__category-dropdown"
        [category_type]="'custom_field'"
        [categoryFormControl]="$any(form.get('category_template_id'))"
        [business_id]="
          system_data?.business_id ? system_data?.business_id : parameters?.business_id
        " />
    </div>

    <div class="flex flex-col gap-2 mb-4" data-testid="custom-fields-form__type">
      <label for="custom_type" class="font-medium">{{ 'Custom field type' | translate }}</label>
      <p-select
        data-testid="custom-fields-form__type-select"
        optionValue="code"
        optionLabel="name"
        [formControlName]="'custom_type'"
        [options]="custom_type_options"
        [styleClass]="'w-full'"
        [showClear]="false" />
      <small class="block mt-1 leading-6 text-xs">
        {{ 'Select the custom field type.' | translate }}</small
      >
      <offconon-shared-ui-form-errors [ctrl]="form.get('custom_type')" />
    </div>

    <div class="flex flex-col gap-2 mb-4" data-testid="custom-fields-form__language">
      <label class="font-medium">{{ 'Language' | translate }}</label>
      <offconon-langauge-dropdown-virtual-scroll-common
        data-testid="custom-fields-form__language-dropdown"
        [selectedId]="selected_language_id"
        [parameters]="language_parameters"
        [languageFormControl]="languageFormControl" />

      <small class="block mt-1 leading-6 text-xs">{{
        'Our system supports multilingual display. Select the language you want to add or edit.'
          | translate
      }}</small>
      <offconon-shared-ui-form-errors [ctrl]="form.get('language_id')" />
    </div>

    @if (this.form.value['custom_type'] === 'text') {
      <div class="flex flex-col gap-2 mb-4 mt-4" data-testid="custom-fields-form__prefix">
        <p-floatlabel>
          <input
            data-testid="custom-fields-form__prefix-input"
            formControlName="prefix"
            id="prefix"
            type="text"
            pInputText
            class="w-full" />
          <label for="prefix">
            {{ 'Prefix' | translate }}
          </label>
        </p-floatlabel>
        <small class="block mt-1 line-height-3 text-xs">{{ 'Optional' | translate }}</small>
      </div>
    }

    <div class="flex flex-col gap-2 mb-4 mt-4" data-testid="custom-fields-form__name">
      <p-floatlabel>
        <input
          data-testid="custom-fields-form__name-input"
          formControlName="name"
          id="name"
          type="text"
          pInputText
          class="w-full" />
        <label for="name">
          {{ 'Name' | translate }}
        </label>
      </p-floatlabel>
      <small class="block mt-1 line-height-3 text-xs">
        {{ 'Mandatory' | translate }}. {{ 'Enter a name for the custom field.' | translate }}</small
      >
      <offconon-shared-ui-form-errors [ctrl]="form.get('name')" />
    </div>

    @if (
      (this.form.value['si_filter_access'] === 'No' && this.form.value['custom_type'] === 'text') ||
      this.form.value['custom_type'] === 'input_number'
    ) {
      <div class="flex flex-col gap-2 mb-4 mt-4" data-testid="custom-fields-form__suffix">
        <p-floatlabel>
          <input
            data-testid="custom-fields-form__suffix-input"
            formControlName="suffix"
            id="suffix"
            type="text"
            pInputText
            class="w-full" />
          <label for="suffix">
            {{ 'Suffix' | translate }}
          </label>
        </p-floatlabel>
        <small class="block mt-1 line-height-3 text-xs">
          {{
            'You can specify a suffix or even select Si units to appear after the field.'
              | translate
          }}</small
        >
      </div>
    }

    <div class="flex flex-col gap-2 mb-4" data-testid="custom-fields-form__description">
      <label for="description" class="font-medium"> {{ 'Description' | translate }}</label>
      <textarea
        data-testid="custom-fields-form__description-input"
        id="description"
        type="text"
        pTextarea
        rows="3"
        name="description"
        [autoResize]="false"
        [formControlName]="'description'"></textarea>
      <small class="block mt-1 leading-6 text-xs">
        {{ 'Optional' | translate }}.
        {{
          'This text will appear below the custom field to help describe the purpose of the field.'
            | translate
        }}
      </small>
    </div>

    @if (this.form.value['custom_type'] === 'input_number') {
      <div class="flex flex-col gap-2 mb-4">
        <label for="is_decimal" class="font-medium">{{
          'What number do you accept?' | translate
        }}</label>
        <p-select
          optionValue="code"
          optionLabel="name"
          [formControlName]="'is_decimal'"
          [options]="is_decimal_options"
          [styleClass]="'w-full'"
          [showClear]="false" />
      </div>

      <div class="flex flex-col gap-2 mb-4">
        <label class=" "> {{ 'Minimum value' | translate }}</label>
        <p-inputNumber
          formControlName="min"
          buttonLayout="horizontal"
          inputId="horizontal"
          spinnerMode="horizontal"
          decrementButtonClass="p-button-text p-button-danger py-1 px-1"
          incrementButtonClass="p-button-text p-button-success py-1 px-1"
          incrementButtonIcon="pi pi-plus"
          decrementButtonIcon="pi pi-minus"
          [inputStyleClass]="
            'w-full text-center py-3 px-1 border-transparent text-primary font-bold'
          "
          [style]="{ width: '100%' }"
          [styleClass]="'border-none '"
          [showButtons]="true"
          [step]="form.value['is_decimal'] ? 0.01 : 1"
          [min]="form.value['is_decimal'] ? 0.01 : 1"
          [max]="9000000000000" />
      </div>

      <div class="flex flex-col gap-2 mb-4">
        <label class=" "> {{ 'Maximum value' | translate }}</label>
        <p-inputNumber
          formControlName="max"
          buttonLayout="horizontal"
          inputId="horizontal"
          spinnerMode="horizontal"
          decrementButtonClass="p-button-text p-button-danger py-1 px-1"
          incrementButtonClass="p-button-text p-button-success py-1 px-1"
          incrementButtonIcon="pi pi-plus"
          decrementButtonIcon="pi pi-minus"
          [inputStyleClass]="
            'w-full text-center py-3 px-1 border-transparent text-primary font-bold'
          "
          [style]="{ width: '100%' }"
          [styleClass]="'border-none '"
          [showButtons]="true"
          [step]="form.value['is_decimal'] ? 0.01 : 1"
          [min]="form.value['min']"
          [max]="9000000000000" />
      </div>
    }

    @if (
      this.form.value['custom_type'] === 'input_number' ||
      this.form.value['custom_type'] === 'range'
    ) {
      <div class="flex flex-col gap-2 mb-4" data-testid="custom-fields-form__si-filter">
        <label for="si_filter_access" class="font-medium">
          {{
            this.form.value['custom_type'] === 'range'
              ? ('Integrated international system of units unit and currency filter' | translate)
              : ('International system of units filter' | translate)
          }}
        </label>
        <p-select
          data-testid="custom-fields-form__si-filter-select"
          optionValue="code"
          optionLabel="name"
          [formControlName]="'si_filter_access'"
          [styleClass]="'w-full'"
          [options]="
            this.form.value['custom_type'] === 'range'
              ? si_filter_access_options_range
              : si_filter_access_options
          "
          [showClear]="false"
          (onChange)="resetSiUnitName()" />
        <small class="block mt-1 leading-6 text-xs">
          {{
            'You can optionally choose a suffix from the International System of Units.' | translate
          }}
        </small>
      </div>
    }

    @if (
      this.form.value['custom_type'] === 'range' &&
      this.form.value['si_filter_access'] === 'Currency'
    ) {
      <div class="flex flex-col gap-2 mb-4" data-testid="custom-fields-form__currency">
        <label class="font-medium"> {{ 'Currency' | translate }}</label>
        <offconon-all-currency-dropdown-list
          data-testid="custom-fields-form__currency-dropdown"
          [selected_iso_code]="system_data?.currency"
          (selectedCurrencyEvent)="setCurrency($event)" />
        <small class="block mt-1 leading-6 text-xs">{{
          'Select the currency used for the range value.' | translate
        }}</small>
      </div>
    }
    @if (
      this.form.value['si_filter_access'] === 'Yes' &&
      (this.form.value['custom_type'] === 'input_number' ||
        this.form.value['custom_type'] === 'range')
    ) {
      <div class="flex flex-col gap-2 mb-4" data-testid="custom-fields-form__unit-type">
        <label for="si_unit_type_filter" class="font-medium">{{ 'Unit type' | translate }}</label>
        <p-select
          data-testid="custom-fields-form__unit-type-select"
          optionValue="code"
          optionLabel="name"
          [formControlName]="'si_unit_type_filter'"
          [styleClass]="'w-full'"
          [options]="si_unit_type_filter_options"
          [showClear]="false"
          (onChange)="onChange($event)" />
      </div>

      <div class="flex flex-col gap-2 mb-4" data-testid="custom-fields-form__unit-name">
        <label class="font-medium">{{ 'Unit name' | translate }}</label>
        <offconon-si-units-dropdown-select
          data-testid="custom-fields-form__unit-name-dropdown"
          [unit_type]="this.form.value['si_unit_type_filter']"
          (selectedSiUnitEvent)="setSiUnit($event)" />
        <small class="block mt-1 leading-6 text-xs">
          {{ 'Select the name of the unit that will appear as a suffix.' | translate }}
        </small>
      </div>
    }

    @if (
      this.form.value['custom_type'] !== 'text' &&
      this.form.value['custom_type'] !== 'input_number' &&
      this.form.value['custom_type'] !== 'range'
    ) {
      <div
        class="field mb-4 mt-5 border-top-1 surface-border w-full"
        data-testid="custom-fields-form__values">
        <div class="text-xl font-medium mb-2 mt-5 text-center">
          {{ 'Custom field values' | translate }}
        </div>
        <div class="text-sm font-light leading-6 text-center">
          {{ 'These values appear as options in the selected custom field options.' | translate }}
        </div>
      </div>
    }

    @if (this.form.value['custom_type'] === 'range') {
      <div
        class="field mb-4 mt-5 border-top-1 surface-border w-full"
        data-testid="custom-fields-form__value-range">
        <div class="text-xl font-medium mb-2 mt-5 text-center">
          {{ 'Custom field value range' | translate }}
        </div>
        <div class="text-sm font-light leading-6 text-center">
          {{
            'These value range appear as options in the selected custom field options.' | translate
          }}
        </div>
      </div>
    }
  </div>

  @if (
    this.form.value['custom_type'] !== 'text' &&
    this.form.value['custom_type'] !== 'input_number' &&
    this.form.value['custom_type'] !== 'range'
  ) {
    <offconon-add-custom-filed-value-row-form
      data-testid="custom-fields-form__add-value-row"
      [form]="form" />
    <div class="pt-3">
      <p-divider class="w-full" />
    </div>
  }

  @if (this.form.value['custom_type'] === 'range') {
    <offconon-range-row-custom-filed-form
      data-testid="custom-fields-form__range-row"
      [si_unit_name]="si_unit_name" />

    <div class="pt-3">
      <p-divider class="w-full" />
    </div>
  }

  <div class="flex flex-row-reverse flex-wrap" data-testid="custom-fields-form__actions">
    <div class="flex items-center justify-center">
      <p-button
        data-testid="custom-fields-form__save-button"
        icon="pi pi-save"
        iconPos="left"
        type="submit"
        [styleClass]="'w-full'"
        label="{{ 'Save' | translate }}"
        [loading]="loading"
        [disabled]="form.invalid"
        [rounded]="true" />
    </div>
  </div>
</form>
